import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const Testimonials = () => {
  return (
    <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
        dynamicHeight
    >
        <div>
            <div className="myCarousel">
                <h3>Latifa Pelletier-Ahmed</h3>
                <h4>Msc. Herbal Medicine, Bsc. Botany</h4>
                <p>I have really enjoyed visiting Dr. Kwok, she is a <b>very knowledgeable</b> and <b>thoughtful</b> practitioner. Her <b>openness</b> allowed me to feel at ease when sharing my health concerns with her. I have found her treatments <b>powerful</b> and <b>moving</b>. Her <b>support</b> has been invaluable as I find ways through my health challenges.</p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Hercy Lapuz</h3>
                <p>
                    I saw Florence for my Acupuncture treatment for <b>foot pain</b> that I've been having for <b>almost 2 years</b>. <b>Immediately after the first session my foot was feeling better</b>. I also addressed a few other issues including Sinus problems and Hormonal imbalance. And she is also helping me treat those areas. I know these issues take time and will have to do several treatments in order to see results but I am happy with the outcome from only my first session with her. My <b>mood has also improved significantly</b> since stress has also been a factor for me dealing with all the chronic issues I've been having.<br/><br/>
                    I'm confident she will help treat all the issues I have since I felt better after only one session. If you're hesitant to do any Acupuncture treatments, don't be. You're in good hands with Florence. She is <b>very knowledgeable</b> and is <b>very helpful</b> and <b>kind</b>. I <b>highly recommend</b> her for Acupuncture treatments.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Stuart</h3>
                <p>
                    As someone who has <b>suffered mental, emotional and physical trauma</b> most of my life (mostly at <b>childhood</b>), I have had to deal with everything from <b>Anger to depression</b>, as well as physical issues from a compromised immune system to muscle pains and in the past few years tinnitus that came on after some extreme emotional trauma.  I've seen multiple therapists that deal with the mind and those that deal with the body. One area that I haven't had much success with, in the past was with Acupuncture.  As we all know, Acupuncture has been around longer than all of us, but in the 4 prior times I tried it, the results were quite poor.<br/><br/>
                    Recently Health Quest Calgary gained a new Acupuncture therapist - Dr. Florence Kwok.  I didn't know she had joined the team, but one of <b>my colleagues</b> went to see her and couldn't stop <b>complimenting her</b>. Based on the positive results she experienced, I thought I may as well book with Florence and see.<br/><br/>
                    Florence is <b>extremely kind and knowledgeable about conventional and traditional healing</b>.<br/><br/>
                    Based on a list of agreed upon priorities, it was determined that Florence would begin therapy on helping me relax and some help taming my anger.<br/><br/>
                    <b>The results were stunning and fast</b>.  I noticed an immediate difference and was so glad.  The duration wasn't overly long, but I was so impressed that I had to try again.  On my second visit, Florence increased the treatment and again, the results were amazing.  This time the <b>duration of calm lasted much longer</b>. Since then, I have been 3 more times and we have now added treating the tinnitus that I am dealing with.<br/><br/>
                    <b>Each treatment has me feeling better and longer</b> and even overall, <b>I feel so much better on multiple levels</b>.<br/><br/>
                    I didn't know that Acupuncture could be so effective, but it obviously can be in the hands of someone so <b>caring, dedicated, knowledgeable and skilled</b>.<br/><br/>
                    <b>I HIGHLY recommend Florence</b> and look forward to continuing my journey to better health in her hands.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Macy</h3>
                <p>
                    I saw Dr. Kwok for <b>microneedling</b>. Even though I am typically sensitive when it comes to needles, with the <b>numbing cream</b>, I didn't feel any pain or discomfort during the procedure. During and after the treatment, Dr. Kwok used <b>quality, natural products</b>. I especially enjoyed the organic aloe vera gel that is applied after the microneedling is complete.<br/><br/>
                    I found my <b>recovery to be much quicker</b> than expected (almost back to normal by the next morning), though it can vary from person to person. I have had blackheads, large pores, oily T-zone, and undesirable texture on my skin for a long time that I had not been able to find effective treatment for.<br/><br/>
                    But I saw <b>great improvement after just the first session</b> with Dr. Kwok. My <b>blackheads and pores were reduced</b>, my <b>T-zone was very noticeably less oily</b>, and the <b>texture of my skin had improved significantly</b>.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Gia T.</h3>
                <p>
                    Dr Florence has helped me ease the pain and tightness I’m feeling in my neck, shoulders and back <b>since my car accident</b>. She’s also thoughtful of where I feel uncomfortable and will find a way to make me feel completely comfortable.
                    She also has vast knowledge of Chinese medicine and will tune that in to help you understand what needs improvement. I have seen improvements day by day.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Albert Liu</h3>
                <p>
                    I had acid reflux issues for 2 years before I decided to give acupuncture and Chinese medicine a try.
                    I've tried all kinds of prescription medications and lifestyle changes before, all with no effects.
                    I then started seeing Florence for acupuncture where for the first time I started seeing improvements.
                    Adding on tea also accelerated my recovery. Over the course of two months, my symptoms improved dramatically.
                    Florence also does a great job following up and providing additional support with soup recipes and recommendations for lifestyle changes.
                    I would highly recommend Florence to anyone who is thinking of giving acupuncture and Chinese medicine a try for their issues.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Brian Drage</h3>
                <p>
                    I have been coming to see Dr. Florence Kwok for acupuncture treatments for a number of years, and I am consistently impressed with the level of care and expertise she provides.
                    What sets Florence apart is her genuine concern for my well-being and her compassionate approach to treatment.
                    From the moment I walk into her office, she creates a warm and comfortable environment, making me feel at ease.<br/><br/>

                    Not only is Florence knowledgeable in acupuncture, but she also possesses a deep understanding of Chinese medicine and pharmacology.
                    This breadth of knowledge has provided me with a holistic approach to healing, which I greatly appreciate.
                    I always feel confident in her recommendations and trust her expertise.<br/><br/>

                    I highly recommend Dr. Kwok to anyone seeking acupuncture or other complementary treatments.
                    Trust Florence to provide exceptional care and support on your wellness journey.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Ben Hartney</h3>
                <p>
                Florence is a knowledgeable and approachable practitioner who has offered valuable help with my health.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Pat</h3>
                <p>
                I was referred to Florence Kwok by my husband who in turn received acupuncture treatments from her.
                I am very grateful and in gratitude to Florence in providing me Acupuncture therapy as it has improved my health and well being immensely.
                She is a very caring and open person and very knowledgeable in her field of work.
                I highly recommend consulting with her for acupuncture therapy and care.
                Florence Kwok has a Doctorate of Traditional Chinese Medicine (TCM) and Acupuncture.
                Her type of Acupuncture focuses on the internal nature of the body (root cause), rather than just on external symptoms.
                I have received acupuncture treatment from a physiotherapist & chiropractor before.
                But acupuncturists have more knowledge in Chinese Medicine especially when it comes to mental and emotional issues rather than just physical pain.<br/><br/>
                
                I started my chief complaint with heel spur & hip pain. But after some treatments with detox (physiological and emotional) and lymphatic drainage, I even sleep better and feel better emotionally & mentally, which are definitely an extra bonus.
                I do feel more balance with my body, mind and spirit.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Dale</h3>
                <p>
                I consulted Dr. Kwok regarding my knee pain secondary to arthritis and narrowing of cartilage as well as arthritis at the base of both thumbs. 
                After a thorough head-to-toe assessment when she also found other health issues, she prescribed acupuncture and herbal remedies. 
                The acupuncture provided rapid reduction in pain, allowing me to ski and walk more comfortably. 
                Following acupuncture, herbal remedies provided continued pain reduction and prevention as well as addressing other health issues discovered during the initial assessment.<br/><br/>
                
                Almost two months after my last acupuncture treatment, I continue to benefit from lower pain levels. 
                Herbal remedies provide relief from the occasional flare-up of arthritis pain, especially when I overdo skiing! I highly recommend Dr. Kwok as she takes a holistic approach to her clients’ health needs. 
                Her approach reveals connections between the primary problem (such as arthritis pain) and body organs. 
                Dr. Kwok also provides suggestions that clients can adopt in their lifestyles.
                </p>
            </div>
        </div>
        <div>
            <div className="myCarousel">
                <h3>Melissa D.</h3>
                <p>
                I met Florence at an event and we instantly connected. 
                She explained to me about trauma and pain, as well as functions of the body from a holistic approach after a car accident. 
                I was already 1 year post accident. 
                The consequence of being less mobile after the accident has accumulated more fluid retention, hence more pain and soreness everywhere in my body. 
                I have always been afraid of needles. I was terrified for my first appointment. 
                Florence helped to guide me with what to expect. She had a very gentle approach. She explained what is happening and how acupuncture can help. 
                With each needle she told me what she was doing. It helped to make me feel relaxed. After all this time, acupuncture was not as bad as I thought. I hardly felt anything.<br/><br/>

                I don't remember if I experienced anything after my first visit, but that first treatment started to help me make some great progress in my healing journey. 
                I was able to get 4 treatments covered from my accident. 
                I felt great! My range of motion was improving, my moods were improving. I was starting to feel some hope.<br/><br/>

                Then, I did not attend acupuncture for a couple weeks. My body noticed. I became irritated again and just feeling off. 
                Now I go at least 2-3 time's a month. The progress that has been made with Florence... I have no words to describe it. 
                I was able to start slowly hiking again (a passion of mine). My headaches and body pain started to take a back seat and not be so prominent. 
                I was laughing again and I felt alive. Acupuncture is working very well alongside my other healing practitioners. 
                I would 100% recommend Dr. Florence Kwok. Thank you for helping me. 
                I can't wait until I am fully able to recover my life, after a car accident. 
                I really appreciate Florence’s knowledge and care in my journey of health.
                </p>
            </div>
        </div>
    </Carousel>
  )
};

export default Testimonials;
