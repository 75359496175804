import acup from "./acup.jpg";
import angel from "./angel.jpg";
import baby from "./baby.jpg";
import cup from "./cup.jpg";
import drKwok from "./chinese-md-stamp.jpg";
import ear from "./ear.jpg";
import faceAcup from "./face-acup.jpg";
import faceCup from "./face-cup.jpg";
import facial from "./facial.jpg";
import guaSha from "./gua-sha.jpg";
import herb from "./herb.jpg";
import microNeedle from "./micro-needle.jpg";
import reiki from "./reiki.jpg";
import map from "./io-map.jpg";
import compressionStockings from "./compression-stockings.jpg";
import infertility from "./fertility.jpg";
import allergy from "./allergy.jpg";

import varicoseKnee from "./varicose-knee.png";
import hotFlash from "./hot-flashes.png";
import bloating from "./bloating.png";
import lips from "./lips.png";
import face3Step from "./freckles.png";
import ankle from "./ankle.png";
import ptsd from "./ptsd.jpg";

export const IMAGES = {
    "acup": acup,
    "allergy": allergy,
    "angel": angel,
    "baby": baby,
    "compressionStockings": compressionStockings,
    "cup": cup,
    "drKwok": drKwok,
    "ear": ear,
    "faceAcup": faceAcup,
    "faceCup": faceCup,
    "facial": facial,
    "guaSha": guaSha,
    "herb": herb,
    "infertility": infertility,
    "map": map,
    "microNeedle": microNeedle,
    "reiki": reiki,
    "ptsd": ptsd,
};

export const testimonialImages = [
    varicoseKnee,
    hotFlash,
    bloating,
    lips,
    face3Step,
    ankle,
];
